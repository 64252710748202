import React from 'react';
import { Text, Box } from '@bighealth/react-limbix-ui';

import {
  PrimaryButton,
} from '../Modal.styles';

import { Warning } from '@/icons';
import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['GENERIC_WARNING'];
  onClose: () => void;
};
const GenericWarning: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const {
    header,
    body,
    height,
    width,
  } = payload;

  return (
    <Box
      width={width ?? '100%'}
      height={height}
      padding="32px 24px 24px 24px"
      position="relative"
    >
      <Box display="flex">
        <Box margin="0px 10px">
          <Warning height="24px" width="24px" stroke="#DF5F30" />
        </Box>
        <Box display="block">
          <Box marginBottom="8px" display="inline-flex">
            <Text fontWeight="700" fontSize="18px">
              {header}
            </Text>
          </Box>
          <Box marginBottom="8px">
            {body}
          </Box>
        </Box>
      </Box>
      <Box position="absolute" bottom="24px" right="24px">
        <PrimaryButton onClick={onClose}>
          Ok
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default GenericWarning;
