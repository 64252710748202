import React, { useState } from 'react';
import {
  FormLabel,
  FormControlLabel,
  InputAdornment,
  IconButton,
  TextField,
  Checkbox,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { Box, Text } from '@bighealth/react-limbix-ui';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useFormContext, Controller, Control } from 'react-hook-form';

import Styled from '../GetSpark.styles';
import '../GetSpark.css';

import LinkIcon from '@/assets/LinkIcon.svg';
import { GetSparkFormType, GetSparkFields } from '@/types';
import { PractitionerIdentifierSystemName } from '@/apollo/types';

import { US_STATES } from '@/utils/constants';

type Props = {
  onClickNextButton: () => void;
}

const identifierDataMap = {
  NPI: {
    textBoxLabel: 'National Provider Identifier (NPI)',
    moreInformationIntroText: 'I would like to ',
  },
  STATE_LICENSE: {
    textBoxLabel: 'State License Number',
    moreInformationIntroText: 'I don\'t have my State License Number and/or would like to ',
  },
};

type IdentifierInputProps = {
  systemName: PractitionerIdentifierSystemName;
  control: Control<GetSparkFormType>;
}

const IdentifierInput: React.FC<IdentifierInputProps> = (props: IdentifierInputProps) => {
  const { systemName, control } = props;
  const { register, formState: { errors } } = useFormContext<GetSparkFormType>();

  const label = identifierDataMap[systemName].textBoxLabel;

  if (systemName === PractitionerIdentifierSystemName.Npi) {
    return (
      <TextField
        variant="outlined"
        margin="normal"
        type="number"
        required
        fullWidth
        label={label}
        id={GetSparkFields.IDENTIFIER_VALUE}
        inputProps={{ inputMode: 'numeric' }}
        helperText={errors[GetSparkFields.IDENTIFIER_VALUE]?.message}
        error={!!errors[GetSparkFields.IDENTIFIER_VALUE]}
        {...register(GetSparkFields.IDENTIFIER_VALUE, { required: true })}
      />
    );
  }
  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item xs={12} sm={7}>
        <TextField
          variant="outlined"
          margin="normal"
          type="text"
          required
          fullWidth
          label={label}
          id={GetSparkFields.IDENTIFIER_VALUE}
          inputProps={{ inputMode: 'numeric' }}
          helperText={errors[GetSparkFields.IDENTIFIER_VALUE]?.message}
          error={!!errors[GetSparkFields.IDENTIFIER_VALUE]}
          {...register(GetSparkFields.IDENTIFIER_VALUE, { required: true })}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Controller
          name={GetSparkFields.IDENTIFIER_STATE}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <TextField
              variant="outlined"
              margin="normal"
              select
              fullWidth
              required
              aria-label="select state"
              id={GetSparkFields.STATE}
              label="State"
              defaultValue={value}
              value={value}
              onChange={onChange}
            >
              <MenuItem key="none" value="">
                ---
              </MenuItem>
              {US_STATES.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
    </Grid>
  );
};

const AccountCreation: React.FC<Props> = (props: Props) => {
  const { onClickNextButton } = props;
  const {
    register,
    control,
    formState: { isValid, errors },
    resetField, getValues,
  } = useFormContext<GetSparkFormType>();
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <>
      <Text as="h1" fontWeight="bold">
        Create provider account
      </Text>
      <Text margin="20px 0px">
        <Text as="span" color="red">
          *
        </Text>
        {' '}
        indicates a required field
      </Text>
      <TextField
        variant="outlined"
        margin="normal"
        type="text"
        required
        fullWidth
        autoFocus
        autoCapitalize="none"
        label="Work Email"
        id={GetSparkFields.EMAIL}
        helperText={errors[GetSparkFields.EMAIL]?.message}
        error={!!errors[GetSparkFields.EMAIL]}
        {...register(GetSparkFields.EMAIL, { required: true })}
      />
      <TextField
        variant="outlined"
        margin="normal"
        type={passwordVisible ? 'text' : 'password'}
        fullWidth
        required
        label="Password"
        id={GetSparkFields.PASSWORD}
        helperText={errors[GetSparkFields.PASSWORD]?.message}
        error={!!errors[GetSparkFields.PASSWORD]}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setPasswordVisible((visible) => !visible)}
              >
                {passwordVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...register(GetSparkFields.PASSWORD, { required: true })}
      />
      <Text>
        This email and password combination will be your login credentials for all Big Health products,
        including your Spark Direct demo account and the
        {' '}
        <a href="https://provider.limbix.com/login" rel="noreferrer" target="_blank">
          <Text as="span" color="#1F2E81" underlined>
            Provider Portal
          </Text>
        </a>
        . You will receive further guidance via email upon form submission.
      </Text>
      <IdentifierInput control={control} systemName={getValues(GetSparkFields.IDENTIFIER_SYSTEM)} />
      <Controller
        name={GetSparkFields.IDENTIFIER_SYSTEM}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            id={GetSparkFields.IDENTIFIER_SYSTEM}
            label={"I don't have my NPI"}
            checked={getValues(GetSparkFields.IDENTIFIER_SYSTEM) !== PractitionerIdentifierSystemName.Npi}
            value={value}
            onChange={() => {
              resetField(GetSparkFields.IDENTIFIER_VALUE);
              if (getValues(GetSparkFields.IDENTIFIER_SYSTEM) === PractitionerIdentifierSystemName.Npi) {
                onChange('STATE_LICENSE');
              } else {
                resetField(GetSparkFields.IDENTIFIER_STATE);
                onChange('NPI');
              }
            }}
            control={<Checkbox />}
          />
        )}
      />
      <Text>
        {identifierDataMap[
          getValues(GetSparkFields.IDENTIFIER_SYSTEM) as PractitionerIdentifierSystemName
        ].moreInformationIntroText}
        <a href="https://www.sparkrx.com/faq#contact" rel="noreferrer" target="_blank">
          <Text as="span" color="#089A7E" underlined>
            request more information
            <img className="link-icon" src={LinkIcon} alt="link-icon" />
          </Text>
        </a>
      </Text>
      <TextField
        variant="outlined"
        margin="normal"
        type="text"
        required
        fullWidth
        label="First Name"
        id={GetSparkFields.FIRST_NAME}
        {...register(GetSparkFields.FIRST_NAME, { required: true })}
      />
      <TextField
        variant="outlined"
        margin="normal"
        type="text"
        required
        fullWidth
        label="Last Name"
        id={GetSparkFields.LAST_NAME}
        {...register(GetSparkFields.LAST_NAME, { required: true })}
      />
      <TextField
        variant="outlined"
        margin="normal"
        type="tel"
        inputProps={{ inputMode: 'tel' }}
        required
        fullWidth
        label="Work Phone"
        id={GetSparkFields.PHONE}
        helperText={errors[GetSparkFields.PHONE]?.message}
        error={!!errors[GetSparkFields.PHONE]}
        {...register(GetSparkFields.PHONE, { required: true })}
      />
      <Box display="block" marginTop="20px">
        <FormLabel>Preferred method of communication</FormLabel>
      </Box>
      <Controller
        name={GetSparkFields.PREFERRED_CONTACT_METHOD}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Styled.RadioGroup
            aria-label="preferred contact method"
            id={GetSparkFields.PREFERRED_CONTACT_METHOD}
            value={value}
            onChange={onChange}
          >
            <FormControlLabel
              value="phone"
              label="Phone"
              control={<Styled.Radio />}
            />
            <FormControlLabel
              value="email"
              label="Email"
              control={<Styled.Radio />}
            />
          </Styled.RadioGroup>
        )}
      />
      <Box marginTop="20px">
        <Styled.SubmitButton
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!isValid}
          onClick={onClickNextButton}
        >
          Next
        </Styled.SubmitButton>
      </Box>
    </>
  );
};

export default AccountCreation;
