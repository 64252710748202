import React, { useState } from 'react';
import { Box, Text } from '@bighealth/react-limbix-ui';

import {
  PrimaryButton,
  SecondaryButton,
} from '../Modal.styles';

import { Warning } from '@/icons';
import { GraphQLErrorsType } from '@/types';
import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['CONFIRMATION_POPUP_WITH_MUTATION'];
  onClose: () => void;
};
const ConfirmationPopupWithMutation: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const {
    header,
    mutation,
    body,
    confirmButtonText,
    cancelButtonText,
  } = payload;
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);

  const triggerMutation = async () => {
    const { errors: resultErrors } = await mutation();
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      onClose();
    }
  };

  return (
    <Box width="425px" padding="32px" position="relative">
      <Box display="inline-flex">
        <Box margin="0px 10px">
          <Warning height="24px" width="24px" stroke="#DF5F30" />
        </Box>
        <Box display="block">
          <Text fontWeight="700" fontSize="18px" lineHeight="26px" marginBottom="8px">
            {header}
          </Text>
          <Text fontSize="16px" lineHeight="24px" marginBottom="8px">
            {body}
          </Text>
          {errors.map((error) => (
            <Box key={error.message} color="red">
              {error.message}
            </Box>
          ))}
          <Box display="flex" marginTop="24px">
            <Box margin="0 0 0 auto" display="inline-flex">
              <SecondaryButton onClick={onClose}>
                {cancelButtonText || 'Cancel'}
              </SecondaryButton>
              <PrimaryButton onClick={triggerMutation}>
                {confirmButtonText || 'Confirm'}
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmationPopupWithMutation;
