import React, { useState } from 'react';
import { Box, Text } from '@bighealth/react-limbix-ui';

import { Button, Collapse, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { Close } from '@/icons';

import { DrawerPayloadType } from '@/types/ReduxTypes';

import { useMutation } from '@/hooks/apollo';
import { MutationSendNudgeArgs } from '@/apollo/types';
import { SEND_NUDGE } from '@/apollo/mutations';
import { formatUserName } from '@/utils/stringUtils';
import useToast from '@/hooks/redux/useToast';
import ErrorMessage from '@/components/ErrorMessage';
import NoNudgeDrawerGraphic from '@/assets/NoNudgeDrawerGraphic.svg';

import { FAILED_TO_SEND_NUDGE_ERROR_MESSAGE, NUDGE_QUERY_ERROR_MESSAGE } from '@/utils/constants';
import usePaginatedNudges from '@/hooks/apollo/queries/usePaginatedNudges';

type Props = {
  payload: DrawerPayloadType['SEND_NUDGE'];
  onClose: () => void;
};

const SendNudgeDrawer: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;
  const [mutationErrorMessage, setMutationErrorMessage] = useState<string>(null);
  const [sendNudgeMutation] = useMutation<MutationSendNudgeArgs>(
    SEND_NUDGE,
  );

  const { showToast } = useToast();

  const {
    nudgeOpportunityUid,
    careteam,
    messageBody,
    patientFirstName,
    patientLastName,
    providerNotification,
    queryErrorExists,
    onSuccess,
  } = payload;
  const { refetch } = usePaginatedNudges();
  const handleSendNudge = async () => {
    const { data, errors: resultErrors } = await sendNudgeMutation({
      variables: {
        nudgeUid: nudgeOpportunityUid,
        careteamUid: careteam.uid,
      },
    });
    if (resultErrors?.length) {
      setMutationErrorMessage(FAILED_TO_SEND_NUDGE_ERROR_MESSAGE);
    } else if (data?.sendNudge?.success) {
      showToast({
        message: `Nudge sent to ${patientFirstName} 🎉!`,
        severity: 'success',
      });
      if (onSuccess) {
        onSuccess();
      }
      refetch();
      onClose();
    }
  };

  const nudgeMessageText = (
    <Text padding="16px 24px 49px">
      {messageBody}
    </Text>
  );
  const nudgeMessageErrorGraphic = (
    <Box padding="38px 120px">
      <img className="no-nudge-icon" src={NoNudgeDrawerGraphic} alt="no-nudge-icon" />
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="min(420px, 100vw)"
      height="100vh"
    >
      <Box>
        <Box width="48px" height="48px" float="right">
          <IconButton
            aria-label="close drawer"
            onClick={onClose}
          >
            <Close width="24px" height="24px" />
          </IconButton>
        </Box>
        <Box margin="0px 24px">
          <Text padding="24px 0px" fontSize="20px" lineHeight="32px">
            {formatUserName(patientFirstName, patientLastName)}
          </Text>
          <Text fontSize="16px" fontWeight="700" lineHeight="24px">
            {providerNotification}
          </Text>
          <Box margin="24px 0px">
            <Box border="1px solid #0000001f" borderRadius="4px">
              {queryErrorExists ? nudgeMessageErrorGraphic : nudgeMessageText}
            </Box>
          </Box>
          <Collapse in={queryErrorExists}>
            <Alert severity="error">
              {NUDGE_QUERY_ERROR_MESSAGE}
            </Alert>
          </Collapse>
        </Box>
      </Box>
      <Box margin="24px 24px 40px">
        <ErrorMessage message={mutationErrorMessage} />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSendNudge}
          disabled={queryErrorExists}
        >
          Send Nudge
        </Button>
      </Box>
    </Box>
  );
};

export default SendNudgeDrawer;
