import React from 'react';
import { Tag, Text } from '@bighealth/react-limbix-ui';

import { formatDate } from '@/utils/dateUtils';

const formatStatusTagDate = (date: Date) => (
  formatDate(date, 'M/D/YYYY')
);

type BaseStatusTagProps = {
  backgroundColor: string,
  textColor: string,
}
const BaseStatusTag: React.FC<BaseStatusTagProps> = ({
  backgroundColor,
  textColor,
  children,
}) => (
  <Tag bgcolor={backgroundColor} minHeight="22px" padding="1px 8px" borderRadius="2px">
    <Text color={textColor}>
      {children}
    </Text>
  </Tag>
);

type Props = {
  text?: string,
  date?: Date,
};

const StatusTag: React.FC<Props> = (props: Props) => {
  const { text, date } = props;
  switch (text) {
  case 'Access code given': {
    return (
      <BaseStatusTag backgroundColor="#FFF8F6" textColor="#DF5F30">
        {`${text} on ${formatStatusTagDate(date)}`}
      </BaseStatusTag>
    );
  }
  case 'First log in': {
    return (
      <BaseStatusTag backgroundColor="#F2FCF9" textColor="#007B63">
        {`${text} on ${formatStatusTagDate(date)}`}
      </BaseStatusTag>
    );
  }
  case 'Access period ended': {
    return (
      <BaseStatusTag backgroundColor="#F1F4FE" textColor="#1F2E81">
        {`${text} on ${formatStatusTagDate(date)}`}
      </BaseStatusTag>
    );
  }
  default: {
    throw new Error('Unrecognized careteam status');
  }
  }
};

export default StatusTag;
