import React from 'react';
import { Text } from '@bighealth/react-limbix-ui';

import StyledEnrollPatientButton from './EnrollPatientButton.styles';

import { COLORS } from '@/utils/colors';

import useDrawer from '@/hooks/redux/useDrawer';

type Props = {
  buttonColor?: 'light' | 'dark';
}

const EnrollPatientButton: React.FC<Props> = (props: Props) => {
  const { buttonColor } = props;

  const { showDrawer } = useDrawer();
  return (
    <StyledEnrollPatientButton
      variant="outlined"
      style={{
        backgroundColor: buttonColor === 'light' ? COLORS.trueWhite : COLORS.primary1,
        borderColor: buttonColor === 'light' ? COLORS.primary1 : 'none',
      }}
      onClick={() => {
        showDrawer('ENROLL_PATIENT', null);
      }}
    >
      <Text color={buttonColor === 'light' ? COLORS.primary1 : COLORS.trueWhite} fontSize="14px">
        Enroll New Patient
      </Text>
    </StyledEnrollPatientButton>
  );
};

export default EnrollPatientButton;
